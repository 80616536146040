import React, { useMemo, useCallback } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useToggle from "react-use/lib/useToggle";

import Table from "react-bootstrap/Table";
import { useMutation, useQuery } from "@apollo/client";
import {
  useRouteMatch,
  useParams,
  Link,
  NavLink,
  useHistory,
} from "react-router-dom";
import { useTranslation } from "react-i18next";

import { QUERY_CLIENT_SENDGRID_TEMPLATES } from "../../../config/graphql/query";
import { DELETE_CLIENT_SENDGRID_TEMPLATE } from "../../../config/graphql/mutation";
import Pagination from "../../../components/Pagination";
import { toast } from "react-toastify";

const limit = 10;

const TableRow = ({
  item,
  index,
}: {
  item: IClientSendGridTemplate;
  index: number;
}) => {
  const { url } = useRouteMatch();
  const { clientId } = useParams<{ clientId: string }>();
  const history = useHistory();

  const { t } = useTranslation(["client", "common"]);

  const { id, title, template, templateId } = item;

  const [visible, setVisible] = useToggle(false);

  const [onDelete] = useMutation(DELETE_CLIENT_SENDGRID_TEMPLATE, {
    refetchQueries: [
      {
        query: QUERY_CLIENT_SENDGRID_TEMPLATES,
        variables: {
          filter: { client: clientId },
        },
      },
    ],
    variables: { input: { id, client: clientId } },
  });

  const onBeforeDelete = () => {
    onDelete()
      .then(() => {
        toast.success(t("client:client.sendgrid.template.toast.deleted"));

        history.replace(`/client/${clientId}/sendgrid-templates`);
      })
      .catch((error) => {
        toast.error(
          error?.networkError?.result?.errors?.[0]?.message ?? error?.message
        );

        history.replace(`/client/${clientId}/sendgrid-templates`);
      });

    setVisible(false);
  };

  return (
    <>
      <tr>
        <th scope="row">{index + 1}</th>
        <td>{title}</td>
        <td>{template}</td>
        <td>{templateId}</td>
        <td>
          <div className="d-flex">
            <Button
              size="sm"
              variant="danger"
              className="mr-2"
              onClick={() => setVisible(true)}
            >
              {t("common:delete")}
            </Button>
            <Link to={`${url}/${id}`}>
              <Button size="sm" variant="primary">
                {t("common:view")}
              </Button>
            </Link>
          </div>
        </td>
      </tr>
      <Modal
        size="sm"
        show={visible}
        onHide={setVisible}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t("client:client.sendgrid.modal.template.title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("client:client.sendgrid.modal.template.body", { id: id })}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={() => setVisible(false)}>
            {t("common:cancel")}
          </Button>
          <Button size="sm" variant="danger" onClick={onBeforeDelete}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const ClientSendGridTemplateList = React.memo(() => {
  const { url, path } = useRouteMatch();
  const { clientId } = useParams<{ clientId: string }>();

  const { t } = useTranslation(["client", "common"]);

  const { data } = useQuery(QUERY_CLIENT_SENDGRID_TEMPLATES, {
    fetchPolicy: "network-only",
    skip: !clientId,
    variables: {
      filter: {
        client: clientId,
      },
    },
  });

  const sendGridTemplateArr = data?.emailTemplates ?? [];
  const sendGridTemplateCount = sendGridTemplateArr.length ?? 0;

  const renderClientToken = useCallback(
    (item: IClientSendGridTemplate, index) => (
      <TableRow key={item.id} item={item} index={index} />
    ),
    []
  );

  return (
    <div className="container-fluid p-0">
      <ul className="nav nav-tabs mb-4">
        <li className="nav-item">
          <NavLink
            exact
            className="nav-link"
            to={`${url.replace("sendgrid-templates", "sendgrid")}`}
          >
            {t("client:client.sendgrid.nav.apikeys")}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to={url}>
            {t("client:client.sendgrid.nav.templates")}
          </NavLink>
        </li>
      </ul>

      <div className="d-flex justify-content-end align-items-center my-4">
        <div>
          <Link to={`${url}/new`} type="button" className="btn btn-primary">
            {t("client:client.sendgrid.template.button.add")}
          </Link>
        </div>
      </div>
      <>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">
                {t("client:client.sendgrid.template.th.title")}
              </th>
              <th scope="col">
                {t("client:client.sendgrid.template.th.template")}
              </th>
              <th scope="col">
                {t("client:client.sendgrid.template.th.templateid")}
              </th>
              <th scope="col">{t("client:client.tokens.th.actions")}</th>
            </tr>
          </thead>
          <tbody>{sendGridTemplateArr.map(renderClientToken)}</tbody>
        </Table>
        <Pagination documentsCount={sendGridTemplateCount} limit={limit} />
      </>
    </div>
  );
});

export default ClientSendGridTemplateList;
